<template>
  <main v-if="producer">
    <PageTitleContainer>
      <template #default>
        <PageTitle>{{ producer.name }}</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'suppliers.producers.edit', params: { id: producer.id } }">Modifica</router-link>
      </template>
    </PageTitleContainer>

    <div>
      <p>
        <strong>Nome</strong>: {{ producer.name }}<br>
        <strong>Zona</strong>: {{ producer.zoneName }}
      </p>
    </div>

    <div class="mt-3">
      <h4>Dati amministrativi</h4>
      <p>
        <strong>Ragione sociale</strong>: {{ producer.companyName }}<br>
        <strong>Partita IVA</strong>: {{ producer.vatCode }}<br>
        <strong>Codice fiscale</strong>: {{ producer.fiscalCode }}<br>
        <strong>Indirizzo</strong>: {{ producer.addressName }}<template v-if="producer.addressNumber">, {{ producer.addressNumber }}</template><br>
        <strong>Cap</strong>: {{ producer.zipCode }}<br>
        <strong>Città</strong>: {{ producer.city }} ({{ producer.province }}) - {{ producer.country }}<br>
        <strong>Numero di telefono</strong>: {{ producer.phoneNumber || '-' }}<br>
        <strong>Indirizzo email</strong>: {{ producer.emailAddress || '-' }}<br>
      </p>
    </div>

    <div class="mt-3">
      <h4>Note</h4>
      <p>
        {{ producer.notes || '-' }}
      </p>
    </div>

    <div v-if="dealers" class="mt-3">
      <h4>Distributori</h4>
      <button v-if="missingDealers.length > 0" class="btn btn-secondary" @click="$openModal('add')">Aggiungi distributore</button>

      <AwlTable v-if="dealers.length > 0" :heading="tableDealersHeadings" :rows="dealers" striped>
        <template #actions-content="{ row }">
          <ActionDelete @click="removeDealer(row.id)" />
          <ActionShow class="ms-2" :to="{ name: 'suppliers.dealers.show', params: { id: row.id } }" />
        </template>
      </AwlTable>
      <p v-else>Nessun distributore</p>

      <AddDealerToProducerModal v-if="$modals.add" :dealers="missingDealers" @close="$closeModal('add')" @dealerSelected="addDealer" />
    </div>

    <div v-if="wines" class="mt-3">
      <h4>Vini a catalogo del produttore</h4>

      <AwlTable v-if="wines.length > 0" :heading="tableWinesHeadings" :rows="wines" striped>
        <template #column-key-packageName="{ row }">
          {{ row.packageName }} {{ row.packageMaterial }}<br>
          {{ row.packageVolume / 1000 }}L
        </template>

        <template #column-key-purchasePrice="{ value }">
          {{ value / 100 }}€
        </template>

        <template #column-key-defaultSellingPrice="{ value }">
          {{ value / 100 }}€
        </template>

        <template #actions-content="{ row }">
          <ActionShow class="ms-2" :to="{ name: 'inventory.wines.show', params: { id: row.id } }" />
        </template>
      </AwlTable>
      <p v-else>Nessun vino presente</p>
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';
import ActionDelete from '@/libs/Table/components/Actions/ActionDelete.vue';
import AddDealerToProducerModal from '@/views/components/Modals/AddDealerToProducerModal.vue';
import ModalsManagerMixin from '@/libs/Modals/mixins/manager.js';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  Promise.all([
    $api.fetchProducer(to.params.id),
    $api.listDealersByProducer(to.params.id),
    $api.listDealers(),
    $api.getAllWinePackages({
      producer: to.params.id,
    })
  ])
    .then(res => next(vm => {
      vm.producer = res[0]?.data;
      vm.dealers = res[1]?.data?.dealers || [];
      vm.allDealers = res[2]?.data?.dealers || [];
      vm.wines = res[3]?.data?.products || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle, AwlTable, ActionShow, ActionDelete, AddDealerToProducerModal },
  mixins: [ ModalsManagerMixin ],
  data () {
    return {
      loading: false,
      producer: null,
      dealers: null,
      allDealers: null,
      wines: null,
    };
  },
  computed: {
    tableDealersHeadings () {
      return [
        { key: 'name', label: 'Nome' },
      ];
    },
    missingDealers () {
      return (this.allDealers || []).filter(ad => !this.dealers.find(d => d.id === ad.id));
    },
    tableWinesHeadings () {
      return [
        { key: 'wineName', label: 'Vino' },
        { key: 'wineTypeName', label: 'Tipo' },
        { key: 'vintageName', label: 'Anno' },
        { key: 'packageName', label: 'Contenitore' },
        { key: 'quantity', label: 'Quantità' },
        { key: 'purchasePrice', label: 'Prezzo acquisto' },
        { key: 'defaultSellingPrice', label: 'Prezzo vendita' },
      ];
    },
  },
  methods: {
    addDealer (dealerId) {
      this.$api
        .addDealerToProducer(this.producer.id, dealerId)
        .then(res => {
          this.producer = res.data;

          return this.$api.listDealersByProducer(this.producer.id);
        })
        .then(res => {
          this.dealers = res?.data?.dealers || [];
        })
      ;
    },
    removeDealer (dealerId) {
      this.$api
        .removeDealerFromProducer(this.producer.id, dealerId)
        .then(res => {
          this.producer = res.data;

          return this.$api.listDealersByProducer(this.producer.id);
        })
        .then(res => {
          this.dealers = res?.data?.dealers || [];
        })
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
