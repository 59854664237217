<template>
  <DialogModal btnLabel="Aggiungi" title="Nuovo distributore" @close="$emit('close', $event)">
    <template #actions="{ close }">
      <div class="w-100">
        <button class="btn btn-primary" @click="closeModal(close)">Ok</button>
      </div>
    </template>

    <template #default>
      <SelectDealerWidget
        v-model="dealer"
        label="Distributore da aggiungere"
        :dealers="dealers"
      />
    </template>
  </DialogModal>
</template>

<script>

import DialogModal from '@/libs/Modals/components/DialogModal.vue';
import SelectDealerWidget from '@/views/components/Form/Widgets/SelectDealerWidget.vue';

export default {
  components: { DialogModal, SelectDealerWidget },
  props: {
    dealers: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      dealer: null,
    };
  },
  methods: {
    closeModal (close) {
      this.$emit('dealerSelected', this.dealer);
      close();
    },
  },
};

</script>
